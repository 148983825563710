import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoreConfigurationComponent } from './store-configuration/store-configuration.component';
import { OptionComponent } from './companies/option/option.component';
import { LoginComponent } from './companies/login/login.component';
import { OrdersComponent } from './companies/orders/orders.component';
import { SuccessComponent } from './companies/success/success.component';
import { ErrorComponent } from './companies/error/error.component';
import { AuthenticationComponent } from './companies/authentication/authentication.component';
import { RegisterCustomerComponent } from './companies/register-customer/register-customer.component';
import { CustomErrorComponent } from './companies/custom-error/custom-error.component';
import { HomeComponent } from './mobile/home/home.component';
import { OrderListComponent } from './mobile/order-list/order-list.component';

import { OrderListRetiredComponent } from './mobile/order-list-retired/order-list-retired.component';
import { MenuComponent } from './mobile/menu/menu.component';
import { ListValidatedOrdersComponent } from './mobile/list-validated-orders/list-validated-orders.component';
import { WelcomeComponent } from './companies/welcome/welcome.component';

const routes: Routes = [
  { path: '', component: StoreConfigurationComponent, pathMatch: 'full'},
  { path: 'welcome/:companyCode/:storeCode/:path', component: WelcomeComponent, pathMatch: 'full'},
  { path: 'option/:companyCode/:storeCode/:path', component: OptionComponent, pathMatch: 'full'},
  { path: 'login/:companyCode/:storeCode/:path', component: LoginComponent, pathMatch: 'full'},
  { path: 'orders/:companyCode/:storeCode/:path', component: OrdersComponent, pathMatch: 'full'},
  { path: 'success/:companyCode/:storeCode/:path', component: SuccessComponent, pathMatch: 'full'},
  { path: 'error/:companyCode/:storeCode/:path', component: ErrorComponent, pathMatch: 'full'},
  { path: 'authentication/:companyCode/:storeCode/:path', component: AuthenticationComponent, pathMatch: 'full'},
  { path: 'register-customer/:companyCode/:storeCode/:path', component: RegisterCustomerComponent, pathMatch: 'full'},
  { path: 'custom-error/:companyCode/:storeCode/:path', component: CustomErrorComponent, pathMatch: 'full'},
  { path: 'mobile/:companyCode/:storeCode', component: HomeComponent ,pathMatch: 'full' },
  { path: 'mobile/order-list', component: OrderListComponent ,pathMatch: 'full' },
  { path: 'mobile/menu/:companyCode/:storeCode/:documentNumber', component: MenuComponent, pathMatch: 'full' },
  { path: 'mobile/order-list-pending/:companyCode/:storeCode/:documentNumber', redirectTo: 'mobile/order-list-pending/:companyCode/:storeCode/:documentNumber/4' },
  { path: 'mobile/order-list-pending/:companyCode/:storeCode/:documentNumber/:withdrawalModality', component: OrderListComponent, pathMatch: 'full' },
  { path: 'mobile/order-list-retired/:companyCode/:storeCode/:documentNumber', component: OrderListRetiredComponent, pathMatch: 'full' },
  { path: 'mobile/order-list-validated/:companyCode/:storeCode/:documentNumber', component: ListValidatedOrdersComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WithdrawsRoutingModule { }
