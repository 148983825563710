import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Base64 } from 'js-base64';
import { from, Observable } from 'rxjs';
import { ReportTimeAttention, ReportUsability } from '../post-sales/models/models';
import { mergeMap, reduce } from 'rxjs/operators';
import { Attention, PagingResponse } from '../notifications/models/attention.model';
import { PageData } from './followup-page-data.dto';

@Injectable({
  providedIn: 'root'
})
export class OrderAttentionService {

  apiFiles = environment.ApiFiles;
  apiOrderAttention = environment.ApiOrderAttention;
  apiReportAttention = environment.ApiReportAttention;
  apiReportInstore = environment.ApiReportInstore;
  userBasicAuthenticationOrderAttention = environment.userBasicAuthenticationOrderAttention;
  keyBasicAuthenticationOrderAttention = environment.keyBasicAuthenticationOrderAttention;

  constructor(private http: HttpClient) { }

  changeStatusCollaborator(status, username, email, storeCode, company){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ localStorage.getItem('token')
      })
    };

    const body = {
      status: status,
      username:username,
      email :email,
      storeCode:storeCode,
      companyCode:company
    }

    return this.http.put(`${this.apiOrderAttention}/collaborator`, body , httpOptions);
  }

  changeStatusCollaboratorBasicAuth(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic '+Base64.encode(this.userBasicAuthenticationOrderAttention+":"+this.keyBasicAuthenticationOrderAttention)
      })
    };
    return this.http.put(`${this.apiOrderAttention}/collaborator`, body , httpOptions);
  }

  consultOrderAttention(storeCode,companyCode,token){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
      })
    };

    return this.http.get(`${this.apiOrderAttention}/order-attention/${storeCode}/${companyCode}?field-status=progress,pending&type-service=`, httpOptions);
  }

  changeStatusOrderAttention(status: string, id: any, username: string) {
    const body = { id: id, status: status, username: username };
    return this.http.put(`${this.apiOrderAttention}/order-attention`, body , this.getHttpOptionToken());
  }

  consultSummaryAttention(storeCode, company) {
    return this.http.get(`${this.apiOrderAttention}/report-order-attention/${storeCode}/${company}?type-service=`, this.getHttpOptionToken());
  }

  getOrdersAttention(storeId: string, companyCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic '+ Base64.encode(this.userBasicAuthenticationOrderAttention + ":" + this.keyBasicAuthenticationOrderAttention)
      })
    };
    return this.http.get(`${this.apiOrderAttention}/order-attention/${storeId}/${companyCode}?field-status=progress,pending&type-service=`, httpOptions);
  }

  downloadReportAttention(body: any): Observable<any> {
    return this.http.post(`${this.apiReportInstore}/attention/export`, body, this.getHttpOptionToken());
  }

  downloadReportUso(filters: any): Observable<ReportUsability[]> {
    let params = this.getQueryParameters(filters);
    return this.http.get<ReportUsability[]>(`${this.apiReportAttention}/v2/attention/report/percentage-use`, { params });
  }

  downloadReportAttentionPdf(filters: any): Observable<ReportTimeAttention[]>  {
    let params = this.getQueryParameters(filters);
    return this.http.get<ReportTimeAttention[]>(`${this.apiReportAttention}/v2/attention/report/average/attention-time`, { params });
  }

  downloadReportAttentionPickingPdf(filters: any): Observable<ReportTimeAttention[]>  {
    let params = this.getQueryParameters(filters);
    return this.http.get<ReportTimeAttention[]>(`${this.apiReportAttention}/v2/attention/report/average/product-search-time`, { params });
  }

  getQueryParameters(filters: any): HttpParams {
    let params = new HttpParams();
    params = params.set('tenantId', filters.tenantId);
    params = params.set('fromDate', filters.fromDate);
    params = params.set('toDate', filters.toDate);
    params = params.set('stores', filters.stores);
    params = params.set('sales', filters.sales);
    params = params.set('filterByDates',filters.filterByDates);
    if (filters.categories) {
      params = params.set('categories', filters.categories);
    }
    return params;
  }

  getHttpOptionToken(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
  }

  getOrders(status:string, storeCode: string, companyCode: string){
    return this.http.get(`${this.apiOrderAttention}/order-attention/${storeCode}/${companyCode}?field-status=${status}&type-service=Retiro en tienda`, this.getHttpOptionToken());
  }

  getOrdersPickingBoy(status:string, storeCode: string, companyCode: string){
    return this.http.get(`${this.apiOrderAttention}/order-attention/picking/${storeCode}/${companyCode}?field-status=${status}&type-service=Retiro en tienda`, this.getHttpOptionToken());
  }

  changeStatusOrderPickingBoy(status:string,id:any){

    const body = {
      id: id,
      status: status
    }

    return this.http.put(`${this.apiOrderAttention}/order-attention/picking`, body , this.getHttpOptionToken());
  }

  consultSummaryPickingBoy(tenantId: string, storeCode: string, lastHour: boolean, sortByElapsedTime: boolean) {
    return this.http.get(`${this.apiOrderAttention}/report-order-attention/picking/${storeCode}/${tenantId}?type-service=Retiro en tienda&lastHour=${lastHour}&sortByElapsedTime=${sortByElapsedTime}`, this.getHttpOptionToken());
  }

  consultSummaryAttentionWithDrawals(tenantId: string, storeCode: string, lastHour: boolean, sortByElapsedTime: boolean) {
    return this.http.get(`${this.apiOrderAttention}/report-order-attention/${storeCode}/${tenantId}?type-service=Retiro en tienda&lastHour=${lastHour}&sortByElapsedTime=${sortByElapsedTime}`, this.getHttpOptionToken());
  }

  updateUser(username: string, body: any) {
    return this.http.put(`${this.apiOrderAttention}/collaborator/${username}`, body);
  }

  attachedImagesOrderAttention(body: any[]) {
    return from(body)
      .pipe(mergeMap(formData =>  this.http.post(`${this.apiFiles}/images/v1/upload`, formData, this.getHttpOptionToken())))
      .pipe(reduce((results, result) => {
        results.push(result);
        return results;
      }, []));
  }

  getWithdrawal(id: number){
    return this.http.get(`${this.apiOrderAttention}/order-attention/${id}/withdrawal`, this.getHttpOptionToken());
  }

  saveWithdrawal(attentionId: any, body: any) {
    return this.http.post(`${this.apiOrderAttention}/order-attention/${attentionId}`, body, this.getHttpOptionToken());
  }

  startWithdrawal(attentionId: any) {
    return this.http.post(`${this.apiOrderAttention}/order-attention/withdrawal/progress/${attentionId}`, null);
  }

  deliverWithdrawal(attentionId: any, body: any) {
    return this.http.post(`${this.apiOrderAttention}/order-attention/deliver/withdrawal/${attentionId}`, body, this.getHttpOptionToken());
  }

  findAttentions(filters: any): Observable<Attention[]>  {
    let params = new HttpParams();
    params = params.set('tenantId', filters.tenantId);
    params = params.set('storeCode', filters.storeCode);

    if (filters.services !== null && filters.services !== undefined) {
      params = params.set('services', filters.services);
    }

    if (filters.status !== null && filters.status !== undefined) {
      params = params.set('status', filters.status);
    }

    if (filters.saleNote !== null && filters.saleNote !== undefined) {
      params = params.set('saleNote', filters.saleNote);
    }

    if (filters.documentNumber !== null && filters.documentNumber !== undefined) {
      params = params.set('documentNumber', filters.documentNumber);
    }

    return this.http.get<Attention[]>(`${this.apiOrderAttention}/attentions`, { params });
  }

  findAttentionsPaged(filters: any, pageData: PageData): Observable<PagingResponse<Attention>>  {
    let params = new HttpParams();
    params = params.set('tenantId', filters.tenantId);
    params = params.set('stores', filters.stores);

    if (pageData.size !== null && pageData.size !== undefined) {
      params = params.set('size', pageData.size);
    }

    if (pageData.page !== null && pageData.page !== undefined) {
      params = params.set('page', pageData.page);
    }

    if (filters.services !== null && filters.services !== undefined) {
      params = params.set('services', filters.services);
    }

    if (filters.status !== null && filters.status !== undefined) {
      params = params.set('status', filters.status);
    }

    if (filters.saleNote !== null && filters.saleNote !== undefined) {
      params = params.set('saleNote', filters.saleNote);
    }

    if (filters.documentNumber !== null && filters.documentNumber !== undefined) {
      params = params.set('documentNumber', filters.documentNumber);
    }

    if (filters.fromDate !== null && filters.fromDate !== undefined) {
      params = params.set('fromDate', filters.fromDate);
    }

    if (filters.toDate !== null && filters.toDate !== undefined) {
      params = params.set('toDate', filters.toDate);
    }

    if (filters.filterByDates !== null && filters.filterByDates !== undefined) {
      params = params.set('filterByDates', filters.filterByDates);
    }

    return this.http.get<PagingResponse<Attention>>(`${this.apiOrderAttention}/attentions/paginated`, { params });
  }

}
