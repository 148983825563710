<div fxLayout="row" fxLayoutAlign="center center" class="container">
  <mat-card fxFlex="65%" fxFlex.xs="90%">
      <mat-card-title class="title" fxLayout="row" fxLayoutGap="5px">
        <div fxLayout="row" fxFlex="20%" fxLayoutAlign="start center">
          <div class="btn-back-black" (click)="back()">
            <img src="../../assets/image/btn-back-black.png">
          </div>
          <div class="text-back" fxHide.xs (click)="back()" >Atrás</div>
        </div>
        <div fxLayout="row" fxFlex="60%" fxLayoutAlign="center center">
          <div>InStore IR</div>
          <div class="logo-instore"><img src="./assets/icon/icon__logo_32x32.svg"></div>
        </div>
      </mat-card-title>

      <mat-card-content fxLayout="row">
          <div fxLayout="column" fxFlex="50%" fxHide.xs fxHide.sm fxHide.md fxLayoutAlign="center center">
              <div fxLayout="row" class="container-logo" >
                  <img  src="../../assets/image/logo-instore.png" >
              </div>
          </div>
        <div fxLayout="column" class="container-select-corporation" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
          <form autocomplete="off" fxLayout="column" [formGroup]="storeForm" (ngSubmit)="selectStore()">
            <mat-card-subtitle class="subtitle">Gestor de colas</mat-card-subtitle>

            <mat-form-field appearance="fill" class="input-select-store">
              <mat-label>Selecciona la tienda:</mat-label>
              <mat-select formControlName="storeCode" [class.is-invalid]="storeCodeInvalid">
                <mat-option [value]="store.storeCode" *ngFor="let store of stores">
                  {{store.storeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-raised-button color="primary" type="submit" class="button-select-store">Ingresar</button>
          </form>
        </div>
      </mat-card-content>
  </mat-card>
</div>
<app-footer></app-footer>