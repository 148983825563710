import { commons } from './environment.commons';

export const environment = {
    production: false,
    ApiReportAttention: 'https://report-attention-dev.cc.cloudintercorpretail.pe',
    ApiInstore: 'https://instore-dev.cc.cloudintercorpretail.pe/api/customer-care',
    ApiOrders: 'https://order-delivery-dev.cc.cloudintercorpretail.pe',
    ApiPushNotification: 'https://movile-notification-dev.cc.cloudintercorpretail.pe/api/customer-care/v1/send/notification',
    ApiOrderAttention: 'https://order-attention-dev.cc.cloudintercorpretail.pe/api/customer-care/v1',
    ApiLogin: 'https://login-dev.cc.cloudintercorpretail.pe/api/customer-care/auth/v1',
    ApiCustomer: 'https://business-irdgco-dad-customer.cloudintercorpretail.pe/api/business-irdgco-dad-customer/v1/customers/?document=',
    ApiReportInstore: 'https://report-instore-dev.cc.cloudintercorpretail.pe',
    ApiOrderAttentionMobile: 'https://api-instore-dev.cc.cloudintercorpretail.pe/v2',
    ApiWiki: 'https://wiki-dev.cc.cloudintercorpretail.pe/api/v1',
    ApiFiles: 'https://files-dev.cc.cloudintercorpretail.pe/api/customer-care',
    WebPlatform: 'https://instore-platform-dev.cc-irdigital.pe',
    BasicAuthenticationUsernameHPSA: 'withdraw-pro-7fc29882b1abc401b1e24ad54c6ec36b',
    BasicAuthenticationPasswordHPSA: 'f5ba2bcc-ab21-4dbe-82d9-c89446134a11',
    BasicAuthenticationUsernameTPSA: 'withdraw-oe-ae7b395b25f0c6f5646e1434bbaaa9bb',
    BasicAuthenticationPasswordTPSA: '6fe60cda-d459-44a7-9f01-47f539bc0b12',
    BasicAuthenticationUsernameSPSA: 'withdraw-spsa-ed6eb3a61176790325a466409fb184ea',
    BasicAuthenticationPasswordSPSA: '69997984-8f7f-4315-adb2-161edc701f71',
    BasicAuthenticationUsernameRP: 'customer-pr-bdee6841cf2765468e07280e5817c2da',
    BasicAuthenticationPasswordRP: 'maflswewRu51kaxudrih9supramUphey4spowr2clc',
    userBasicAuthenticationOrderAttention: 'usuario',
    keyBasicAuthenticationOrderAttention: 'password',
    system: 'INSTORE',
    commons,
    urlDBFirebase: '/instore/notification/dev',
    urlDBFirebasePostSale: '/instore/postsale/dev',
    urlDBFirebaseQueueManager: '/instore/queue-manager/dev',
    bucketname: 'instore-files-quality',
    pathRoot: 'development/order-attention',
    swWorkerEnable: true,
    firebase: {
        apiKey: "AIzaSyA8pameJ4hr7sAQ3yOYbezTizVfmrhSQUk",
        authDomain: "pe-gcp-customercare-02.firebaseapp.com",
        databaseURL: "https://instore-notification.firebaseio.com",
        projectId: "pe-gcp-customercare-02",
        storageBucket: "pe-gcp-customercare-02.appspot.com",
        messagingSenderId: "52415902569",
        appId: "1:52415902569:web:00f579c663279a9d2743f8",
        measurementId: "G-8CT13VRMZB"
    },
    urlWebPlatformRT: 'https://retiro-en-tienda-dev.cc-irdigital.pe'
};