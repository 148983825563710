import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor() {
    }

    
    markFormGroupTouched(form: FormGroup) {
        Object.values(form.controls).forEach(control => {
           control.markAsTouched();

            if ((control as any).controls) {
               this.markFormGroupTouched(control as FormGroup);
            }
        });
    }
}