export const companies = [
    { value: 'tpsa', viewValue: 'Oeschle' },
    { value: 'hpsa', viewValue: 'Promart' },
    { value: 'spsa', viewValue: 'Plaza Vea' },
    { value: 'rp', viewValue: 'Real Plaza' }
];

export const status = [
    { value: 'pending', viewValue: 'Pendiente', viewValuePickup: 'Retiros pendientes' },
    { value: 'progress', viewValue: 'En progreso', viewValuePickup: 'Retiros en proceso' },
    { value: 'finished', viewValue: 'Finalizado', viewValuePickup: 'Retiros finalizados' }
];

export const statusPicking: any[] = [
    { id: 'pending', value: 'Retiros pendientes' },
    { id: 'progress', value: 'Retiros en proceso' },
    { id: 'finished,delivered', value: 'Retiros finalizados' },
    { id: 'cancelled', value: 'Atenciones canceladas' }
];

export const sale = [
    { value: 'Venta por Internet', viewValue: 'Ecommerce' },
    { value: 'Venta Empresa', viewValue: 'Venta empresa' },
    { value: 'Venta Directa', viewValue: 'Venta retail' }
];

export const sale_report_attention = [
    { value: 'Ecommerce', viewValue: 'Ecommerce' },
    { value: 'Venta Empresa', viewValue: 'Venta empresa' },
    { value: 'Venta retail', viewValue: 'Venta retail' }
];

export const categories = [
    { value: 'FOOD', viewValue: 'Food' },
    { value: 'NONFOOD', viewValue: 'Non Food' }
];

export const users_store = [
    { value: 'CUSTOMER', viewValue: 'Cliente' },
    { value: 'ASSISTANT', viewValue: 'Gestor' }
]

export const systems = [
    { value: 'post-sales', viewValue: 'Servicios Post Venta' },
    { value: 'withdraws', viewValue: 'Retiro en tienda - cliente' },
    { value: 'queue-manager', viewValue: 'Gestor de colas' },
    { value: 'notifications', viewValue: 'Notificaciones retiro en tienda' }
];

export const profiles = [
    { value: 'ROLE_ADMIN', viewValue: 'Central / Corporativo' },
    { value: 'ROLE_USER', viewValue: 'Operación en tienda' },
    { value: 'ROLE_SECURITY', viewValue: 'Seguridad' }
]

export const document_types = [
    { value: '1', viewValue: 'N° Doc. de Identidad' },
    { value: '2', viewValue: 'Nota de Venta' }
]

export const menus = [
    {
        id: 'attention',
        icon: 'user-v1.png',
        iconIsSvg: false,
        name: 'Atenciones',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN',
        ],
        disabled: false,
        showSubItems: false,
        subItems: [
            {
                id: 'progress',
                icon: 'change.png',
                name: 'En progreso',
                link: '/post-sales/instore/progress',
                roles: [
                    'ROLE_USER'
                ]
            },
            {
                id: 'finish',
                icon: 'check.png',
                name: 'Finalizadas',
                link: '/post-sales/instore/finish',
                roles: [
                    'ROLE_USER',
                    'ROLE_ADMIN',
                ]
            }
        ]
    },
    {
        id: 'indicator',
        icon: 'graphic.png',
        iconIsSvg: false,
        link: '/post-sales/instore/indicator',
        name: 'Indicadores',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN'
        ],
        disabled: false
    },
    {
        id: 'report',
        icon: 'dashboard.png',
        iconIsSvg: false,
        link: '/post-sales/instore/report',
        name: 'Reportes',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN'
        ],
        disabled: false
    },
    {
        id: 'user-management',
        icon: 'user-management.png',
        iconIsSvg: false,
        link: '/post-sales/instore/user-management',
        name: 'Gestión de usuarios',
        roles: [
            'ROLE_ADMIN'
        ],
        disabled: false
    },
    {
        id: 'wiki',
        icon: 'wiki.png',
        iconIsSvg: false,
        link: '/post-sales/instore/wiki',
        name: 'Ayuda',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN'
        ],
        disabled: false
    },
    {
        id: 'qr-code',
        icon: 'qrcode.png',
        iconIsSvg: false,
        link: '/post-sales/instore/qr-code',
        name: 'Generación de código QR',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN'
        ],
        disabled: false
    },
    {
        id: 'validate_withdrawal',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="m16.5 17 5-5-5-5-1.41 1.41L17.67 11H8.5v2h9.17l-2.58 2.59L16.5 17z" fill="currentColor"/> <path d="M18.5 19h-14V5h14v2h2V5a2 2 0 0 0-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-2h-2v2z" fill="currentColor"/></svg>',
        iconIsSvg: true,
        link: '/post-sales/instore/validate-withdrawal',
        name: 'Validar retiro del cliente',
        roles: [
            'ROLE_SECURITY'
        ],
        disabled: false
    },
    {
        id: 'validated_attentions',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 8.5H2v-2h5v2zm0 3H2v2h5v-2zm13.59 7-3.83-3.83c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.09l-1.41 1.41zm-3.59-8c0-1.65-1.35-3-3-3s-3 1.35-3 3 1.35 3 3 3 3-1.35 3-3zm-15 8h10v-2H2v2z" fill="currentColor"/></svg>',
        iconIsSvg: true,
        link: '/post-sales/instore/attentions/validated',
        name: 'Ver retiros validados',
        roles: [
            'ROLE_SECURITY'
        ],
        disabled: false
    }
];

export const options = [
    {
        id: 'sanitary-facilities',
        icon: 'sanitary-facilities.png',
        name: 'SS. HH.',
        roles: [
            'ROLE_USER'
        ]
    },
    {
        id: 'food',
        icon: 'food.png',
        name: 'Refrigerio',
        roles: [
            'ROLE_USER'
        ]
    },
    {
        id: 'training',
        icon: 'training.png',
        name: 'Capacitación',
        roles: [
            'ROLE_USER'
        ]
    },
    {
        id: 'meeting',
        icon: 'meeting.png',
        name: 'Reunión',
        roles: [
            'ROLE_USER'
        ]
    },
    {
        id: 'back-office',
        icon: 'back-office.png',
        name: 'Back office',
        roles: [
            'ROLE_USER'
        ]
    },
    {
        id: 'waiting',
        icon: 'like.png',
        name: 'Disponible',
        roles: [
            'ROLE_USER'
        ]
    },
    {
        id: 'exit',
        icon: 'logout.png',
        name: 'Salir',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN',
            'ROLE_SECURITY'
        ]
    }
];

export const optionsNotification = [
    {
        id: 'exit',
        icon: 'logout.png',
        name: 'Salir',
        roles: [
            'ROLE_USER',
            'ROLE_ADMIN',
            'ROLE_SECURITY'
        ]
    }
];

export const authorizedStoresPickupToCar: any[] = [
    {
        tenantId: 'hpsa',
        stores: [
            '517',
            '526'
        ]
    }
]

export const configQrWithdrawal = [
    {
        tenantId: 'tpsa',
        tenantName: 'Oechsle',
        allStores: false,
        storesAvailable: [
            '101',
            '102',
            '103',
            '104',
            '105',
            '107',
            '108',
            '109',
            '110',
            '111',
            '113',
            '114',
            '117',
            '118',
            '119',
            '120',
            '121',
            '122',
            '126',
            '128',
            '129',
            '130',
            '132',
            '135',
            '137',
            '138',
            '901',
            '903',
            '904',
            '905',
            '906',
            '907',
            '908',
            '909',
            '910',
            '911',
            '6000',
            '6001',
            '6002',
            '6003',
            '6004',
            '6005',
            '6006',
            '6007',
            '6008',
            '6009',
            '6010',
            '6011',
            '6012',
            '6013',
            '6014',
            '6015',
            '6016',
            '6017',
            '6018',
            '6019',
            '6021',
            '6022',
            '6024',
            '6025',
            '6026',
            '6027',
            '6028',
            '6029',
            '6030',
            '6031',
            '6032',
            '6033',
            '6034',
            '6035',
            '6036',
            '6037',
            '6038',
            '6039',
            '6040',
            '6041',
            '6042',
            '6043',
            '6044',
            '6045',
            '6046',
            '6047',
            '6048',
            '6049',
            '6050',
            '6051',
            '6053',
            '6054',
            '6055',
            '6056',
            '6057',
            '6058',
            '6059',
            '6060',
            '6061',
            '6062',
            '6063',
            '6064',
            '6065',
            '6066',
            '6067',
            '6068',
            '6069',
            '6070',
            '6071',
            '6072',
            '6073',
            '6074',
            '6075',
            '6076',
            '6077',
            '6078'
        ],
        allStoresRedirect: true,
        storesRedirect: []
    },
    {
        tenantId: 'hpsa',
        tenantName: 'Promart',
        allStores: false,
        storesAvailable: [
            '101',
            '401',
            '402',
            '403',
            '404',
            '501',
            '502',
            '503',
            '504',
            '505',
            '506',
            '507',
            '509',
            '510',
            '511',
            '512',
            '514',
            '515',
            '516',
            '517',
            '518',
            '519',
            '520',
            '521',
            '522',
            '523',
            '524',
            '525',
            '526',
            '527',
            '528',
            '530',
            '531',
            '536',
            '538',
            '540',
            '543',
            '544'
        ],
        allStoresRedirect: true,
        storesRedirect: []
    },
    {
        tenantId: 'spsa',
        tenantName: 'Plaza Vea',
        allStores: true,
        allStoresRedirect: true,
        storesRedirect: []
    },
    {
        tenantId: 'rp',
        tenantName: 'Real Plaza',
        allStores: false,
        storesAvailable: [
            'MOD-AQP-01',
            'MOD-CIVICO-01',
            'MOD-GCV-01',
            'MOD-PIU-01',
            'MOD-PRI-01',
            'MOD-PUR-01',
            'MOD-SLV-01',
            'MOD-SCL-01',
            'MOD-TRU-01',
            'MOD-CIX-01',
            'MOD-VILLAMARIA-01'
        ],
        allStoresRedirect: true,
        storesRedirect: []
    }
]

export const cancellationReasons: any[] = [
    { id: 1, value: 'Cliente volverá en otro momento' },
    { id: 2, value: 'Cliente no cuenta con documento de identidad' },
    { id: 3, value: 'Otra razón' }
];

export const optionsReviewedTheProduct: any[] = [
    { id: 'SI', value: 'Sí' },
    { id: 'NO', value: 'No' },
    { id: 'NA', value: 'NA' }
];

export const optionsSatisfiedWithTheProduct: any[] = [
    { id: 'SI', value: 'Sí' },
    { id: 'NO', value: 'No' }
];

export const typeReport: any[] = [
    { id: 'COMPLETED_WITHDRAWALS', value: 'Retiros finalizados' },
    { id: 'CANCELED_ATTENTIONS', value: 'Atenciones canceladas' }
];

export enum Service {
    RETURN = 1,
    TECHNICAL_SERVICE,
    OTHERS,
    WITHDRAW,
    WITHDRAW_CAR
}

export const withdrawalValidationEnabled: any[] = [ 'hpsa', 'tpsa', 'spsa' ];

export const withdrawalModalityConfig: any[] = [
    { id: 4, icon: './assets/icon/menu__pickup_in_store.svg', value: 'Retiro en tienda' },
    { id: 8, icon: './assets/icon/menu__withdrawal_at_auto_in_store.svg', value: 'Retiro al auto' }
];

export const usersWithCompanyChange: any[] =  ['jeam.navarrete@indigitalxp.pe','luis.torres@indigitalxp.pe'];

export const imagesWithdrawalDetail: any[] =  [
    {id: 0, name: 'imageproduct', description: 'Tómale foto al<b>&nbspcliente con el producto</b>' },
    {id: 1, name: 'imagevoucher', description: 'Tómale foto al<b>&nbspcomprobante de pago</b>' },
    {id: 2, name: 'imagemail', description: 'Tómale foto al<b>&nbspcorreo de confirmación de recojo</b>' },
    {id: 3, name: 'imagesustento1', description: '' },
    {id: 4, name: 'imagesustento2', description: '' },
    {id: 5, name: 'imagefirma', description: '' },
];

export const domainsAllowedInRegister: any[] = [
    '@promart.pe',
    '@promart.ec',
    '@spsa.pe',
    '@oechsle.pe',
    '@realplaza.com.pe',
    '@irdigital.pe',
    '@indigitalxp.pe',
    '@plazavea.pe',
    '@plazavea.pe',
    '@oslo.pe',
    '@tiendasmass.pe',
    '@exacta.pe'
];

export const versionApp = 'v1.14.13';

export const commons = {
    companies,
    systems,
    service: Service,
    status,
    statusPicking,
    sale,
    categories,
    profiles,
    users_store,
    menus,
    options,
    optionsNotification,
    configQrWithdrawal,
    usersWithCompanyChange,
    imagesWithdrawalDetail,
    versionApp,
    cancellationReasons,
    optionsReviewedTheProduct,
    optionsSatisfiedWithTheProduct,
    typeReport,
    withdrawalValidationEnabled,
    withdrawalModalityConfig,
    authorizedStoresPickupToCar,
    itemsPerPage: [10, 15, 20, 25],
    itemsUserPage: [5,10,15],
    domainsAllowedInRegister
}
