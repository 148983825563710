import { environment } from "src/environments/environment";

export class GenericHelper {

    private static configQrWithdrawal: any[] = environment.commons.configQrWithdrawal;

    public static isNullOrEmpty(value: any) {
        return value === undefined || value === null || value.toString().trim() === '';
    }

    public static isNullOrArrayEmpty(value: any) {
        return value === undefined || value === null || value.length === 0;
    }

    public static isValidDomain(email: string, domains: string[]) {
        let flag = domains.some(element => {
            return email.endsWith(element);
        });
        return flag;
    }

    public static checkWithdrawsRedirect(tenantId: string, storeCode: string) {
        let flag = false;
        if (!this.isNullOrEmpty(tenantId) && !this.isNullOrEmpty(storeCode)) {
            let config = this.configQrWithdrawal.find(item => item.tenantId === tenantId);
            if(!this.isNullOrEmpty(config)){
                if (config.storesRedirect.includes(storeCode) || config.allStoresRedirect) {
                    flag = true;
                }
            }
        }
        return flag;
    }

    public static withdrawsRedirect(tenantId: string, storeCode: string) {
        window.location.href = environment.urlWebPlatformRT + '/withdraws/' + tenantId + '/' + storeCode + '/login';        
    }
}