import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/util/util.service';
import { NgForm } from '@angular/forms';
import { InstoreService } from 'src/app/services/instore.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store-configuration',
  templateUrl: './store-configuration.component.html',
  styleUrls: ['./store-configuration.component.scss']
})
export class StoreConfigurationComponent implements OnInit {

  stores: any[];
  companies: any[] = environment.commons.companies;
  store: any;
  company: any;
  usersStore: any[] = environment.commons.users_store;
  userUse: any;

  constructor(public utilService: UtilService,
    public instoreService: InstoreService,
    private router: Router) { }

  ngOnInit(): void {
  }

  loadStores(){
    let response;
    let newError;
    this.instoreService.getStores(this.company).subscribe(data=>{
      response = data;
      this.stores = response;
    },error=>{
        newError = error
        console.log(newError);
    })
  }

  selectStore(form: NgForm){
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }
    localStorage.setItem("companyCode",this.company);
    localStorage.setItem("storeCode",this.store);
    localStorage.setItem("storeName",this.stores.find(element => element.storeCode === this.store).storeName);
    localStorage.setItem("userUse", this.userUse);
    if (this.company == 'tpsa' && this.store == '132') {
      localStorage.setItem("path","1");
    } else if(this.company == 'tpsa' && this.store !='132' ){ 
      localStorage.setItem("path","0");
    } else if(this.company == 'hpsa' && (this.store =='530' || this.store == '524')) {
      localStorage.setItem("path","1");
    } else if(this.company == 'hpsa' && (this.store !='530' && this.store != '524')) {
      localStorage.setItem("path","0");
    } else if (this.company == 'spsa') {
      localStorage.setItem("path","0");
    } else if (this.company == 'rp') {
      localStorage.setItem("path","0");
    }
    this.router.navigate([`/withdraws/welcome/${this.company}/${this.store}/0`]);
  }

  back(){
    this.router.navigate(['/']);
  }

}
