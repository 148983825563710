import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InstoreService } from 'src/app/services/instore.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
  providers: [ InstoreService ]
})
export class StoreListComponent implements OnInit {

  companyCode: string;
  storeForm: FormGroup;
  stores: any = [];

  constructor(private instoreService: InstoreService, private formBuilder: FormBuilder, private router: Router) { 
    this.companyCode = localStorage.getItem('companyCode');
    this.createForm();
  }

  ngOnInit(): void {
    this.loadStores();
  }

  createForm() {
    this.storeForm = this.formBuilder.group({
      storeCode: [ undefined || '', Validators.required ]
    });
  }

  get storeCodeInvalid() {
    return this.storeForm.get('storeCode').invalid && this.storeForm.get('storeCode').touched;
  }

  loadStores() {
    this.instoreService.getStores(this.companyCode).subscribe(res => this.stores = res);
  }

  selectStore() {
    if (this.storeForm.invalid) {
      return Object.values(this.storeForm.controls).forEach(control => control.markAsTouched());
    }
    localStorage.setItem('storeCode', this.storeForm.value.storeCode);
    this.router.navigate(['queue-manager/home']);
  }

  back(){
    this.router.navigate(['/']);
  }

}
