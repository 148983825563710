import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { WithdrawsRoutingModule } from './withdraws-routing.module';

import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { QRCodeModule } from 'angularx-qrcode';

import { InputValidateDirective } from './util/Input-validator-directive';
import { FirstWordPipe } from './util/first-word.pipe';

import { InstoreService } from '../services/instore.service';
import { OrderService } from '../services/order.service';
import { CustomerService } from '../services/customer.service';

import { StoreConfigurationComponent } from './store-configuration/store-configuration.component';
import { FooterComponent } from './footer/footer.component';
import { OptionComponent } from './companies/option/option.component';
import { LoginComponent } from './companies/login/login.component';
import { OrdersComponent } from './companies/orders/orders.component';
import { LoadingComponent } from './companies/loading/loading.component';
import { OrderCardComponent } from './companies/components/order-card/order-card.component';
import { SuccessComponent } from './companies/success/success.component';
import { ErrorComponent } from './companies/error/error.component';
import { AuthenticationComponent } from './companies/authentication/authentication.component';
import { RegisterCustomerComponent } from './companies/register-customer/register-customer.component';
import { CustomErrorComponent } from './companies/custom-error/custom-error.component';
import { HomeComponent } from './mobile/home/home.component';
import { OrderListComponent } from './mobile/order-list/order-list.component';
import { OrderCardMobileComponent } from './mobile/components/order-card-mobile/order-card-mobile.component';
import { MenuComponent } from './mobile/menu/menu.component';
import { FooterMobileComponent } from './mobile/components/footer-mobile/footer-mobile.component';
import { HeaderMobileComponent } from './mobile/components/header-mobile/header-mobile.component';
import { OrderRetiredMobileComponent } from './mobile/components/order-retired-mobile/order-retired-mobile.component';
import { OrderPendingMobileComponent } from './mobile/components/order-pending-mobile/order-pending-mobile.component';
import { OrderRetiredCardMobileComponent } from './mobile/components/order-retired-card-mobile/order-retired-card-mobile.component';
import { OrderListRetiredComponent } from './mobile/order-list-retired/order-list-retired.component';
import { ListValidatedOrdersComponent } from './mobile/list-validated-orders/list-validated-orders.component';
import { ValidatedOrderMobileComponent } from './mobile/components/validated-order-mobile/validated-order-mobile.component';
import { ModalWithdrawalValidatedComponent } from './mobile/components/modal-withdrawal-validated/modal-withdrawal-validated.component';
import { WelcomeComponent } from './companies/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    StoreConfigurationComponent,
    FooterComponent,
    WelcomeComponent,
    OptionComponent,
    LoginComponent,
    OrdersComponent,
    LoadingComponent,
    OrderCardComponent,
    SuccessComponent,
    ErrorComponent,
    AuthenticationComponent,
    RegisterCustomerComponent,
    CustomErrorComponent,
    HomeComponent,
    OrderListComponent,
    OrderCardMobileComponent,
    InputValidateDirective,
    FirstWordPipe,
    FooterMobileComponent,
    HeaderMobileComponent,
    OrderRetiredMobileComponent,
    OrderPendingMobileComponent,
    OrderRetiredCardMobileComponent,
    OrderListRetiredComponent,
    MenuComponent,
    ListValidatedOrdersComponent,
    ValidatedOrderMobileComponent,
    ModalWithdrawalValidatedComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    WithdrawsRoutingModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    FlexLayoutModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogModule,
    QRCodeModule,
    SharedModule
  ],
  providers:[
    InstoreService,
    OrderService,
    CustomerService,
  ],
  exports: [
    FooterComponent
  ]
})
export class WithdrawsModule { }
